import Vue, {getCurrentInstance, provide} from "vue";
import App from "./App.vue";
import router from "./router";

import {createPinia, PiniaVuePlugin} from "pinia";
import {createHead} from "@unhead/vue";
import {UnheadPlugin} from "@unhead/vue/vue2";
import BootstrapVue from "bootstrap-vue";

import "./ext/vue-notification";
import "./ext/sentry";

import globals from "./globals";
import Popper from "popper.js";
import {useAppStore} from "@/stores/app";
import {Settings} from "luxon";
import {$notify} from "@/injection/keys";

// Required to enable animations on dropdowns/tooltips/popovers
// @ts-ignore
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false;

Vue.config.productionTip = false;

Vue.use(BootstrapVue);

// Global RTL flag
Vue.mixin({
  data: globals,
});

const head = createHead();
Vue.use(UnheadPlugin);

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

const store = useAppStore(pinia);

Settings.defaultZone = store.timezone;

store.fetchNotifications().catch((err) => {
  console.error("Error fetching notifications: ", err);
});
store.fetchConfig().catch((err) => {
  console.error("Error fetching config: ", err);
});
store
  .fetchIndex()
  .catch((err) => {
    console.error("Error initializing...", err);
  })
  .finally(() => {
    new Vue({
      router,
      pinia,
      // @ts-ignore: not ideal, but cba to figure out the shim or w/e
      unhead: head,
      setup: () => {
        const instance = getCurrentInstance();
        // noinspection TypeScriptUnresolvedVariable
        provide($notify, instance?.proxy.$notify);
      },
      render: (h) => h(App),
    }).$mount("#app");
  });
